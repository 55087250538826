.projects__wrappers {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
}

.project-card {
  width: 100%;
  border-radius: 2rem;
  background-color: $black-100;
  padding: 1.2rem;

  @include desktop-screen {
    width: 360px;
  }
}

.project-card__head {
  position: relative;
  height: 250px;
  width: 100%;

  > img {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    // object-fit:contain;
  }
}

.project-card__head-badge {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  border-radius: 50%;
  background: linear-gradient(to right, #434343, #000000);

  img {
    width: 50%;
    height: 50%;
  }
}

.project-card__content {
  margin-top: 1.25rem;

  h3 {
    font-size: 24px;
    font-weight: 700;
    color: $white;
  }

  p {
    margin-top: 0.5rem;
    font-size: 14px;
    color: $white-200;
  }
}

.project-card__foot {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem;
}

.project-main-img {
  width: 100%;
  // object-fit: contain;
  height: 100%;
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2em;

  // Hover effect removed
  &:hover {
    background: linear-gradient(to right, #3acfd5, #3a4ed5);
    padding: 0.1em;
    border-radius: 2em;
  }
}

.blue-text-gradient {
  background: linear-gradient(to top, #2f80ed, #56ccf2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-text-gradient {
  background: linear-gradient(to top, #11998e, #38ef7d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pink-text-gradient {
  background: linear-gradient(to top, #ec008c, #fc6767);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
