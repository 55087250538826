section {
  position: relative;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @include desktop-screen {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.section-header {
  opacity: 1;
  transform: none;
  p {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: $white-200;
    @include desktop-screen {
      font-size: 18px;
    }
  };
  h2 {
    font-weight: 900;
    font-size: 30px;
    color: $white;
    @include desktop-screen {
      font-size: 50px;
    }
  }
}

.section-description {
  margin-top: 1rem;
  max-width: 48rem;
  font-size: 17px;
  line-height: 30px;
  color: $white-200;
  opacity: 1;
  transform: none;
}