.skill__card {
  margin-top: 50px;
  height: 100px;
  margin-bottom: 150px;
  width: 1150px;
  width: 100%;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  /* Mobile screen adjustments */
  @media (max-width: 768px) {
    width: 100%; 
    margin-top: 50px;
    margin-bottom: 200px; 
  }

  /* Small mobile screens */
  @media (max-width: 515px) {
    width: 100% !important; 
    margin-top: 40px;
    margin-bottom: 320px !important;
  }
}

.skill__card-gradient {
  width: 100%;
  border-radius: 20px;
  padding: 2px;
  background: "#00cea8";
  background: linear-gradient(90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  background: -webkit-linear-gradient(-90.13deg, #00cea8 1.9%, #bf61ff 97.5%);
  opacity: 1;
  transform: none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0px 35px 120px -15px #211e35;
}

.skill__card-content {
  display: flex;
  min-height: 210px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 20px;
  background-color: $black-100;
  padding-left: 2rem;
  padding-right: 2rem;
  
  h3 {
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
    color: $white;
    @media (max-width: 515px) {
      font-size: 16px;
      padding: 30px 0px;
      text-align: justify;
    }
  }
  
}
.skill__card:hover {
  transform: scale(1.02); /* Slightly increase size on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0); /* Increase shadow on hover */
}
