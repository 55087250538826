.bg-hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(../../assets/herobg.png);
}

#hero {
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.hero__head {
  position: absolute;
  inset: 0px;
  top: 120px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 80rem;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  > div:first-child {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div:first-child {
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 9999px;
      background-color: $purple;
    }
    div:nth-child(2) {
      height: 10rem;
      width: 0.25rem;
      background: -webkit-linear-gradient( -90deg, #804dee 0%, rgba(60, 51, 80, 0) 100% );
      @include desktop-screen {
        height: 20rem;
      }
    }
  }
  h1 {
    margin-top: 2rem;
    font-weight: 900;
    color: $white;
    font-size: 40px;
    @include desktop-screen {
      font-size: 70px;
    }
    span {
      color: $purple;
    }
  }
  p {
    margin-top: 2rem;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
    color: $white-100;

    @media (max-width: 400px) {
      font-size: 17px;
    }
  }
  
}

.hero__foot {
  position: absolute;
  bottom: 8rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  @include desktop-screen {
    bottom: 4rem;
  }
  a > div {
    height: 64px;
    width: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 1.5rem;
    border-width: 4px;
    border-color: $white-200;
    padding: 0.5rem;
    > div {
      margin-bottom: 0.25rem;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 9999px;
      background-color: $white-200;
      transform: translateY(1.25551px) translateZ(0px);
    }
  }
}

.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border-radius: 0.7em;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1em 2em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
}
.fourth{
  border-color: rgb(63, 0, 136);
  color: #fff;
  background: {
    image: linear-gradient(45deg,$purple 50%, transparent 50%);
    position: 100%;
    size: 400%;
  }
  transition: background 300ms ease-in-out;
  
  &:hover {
    background-position: 0;
  }
}

a, a:hover {
	text-decoration: none;
}

.socialbtns, .socialbtns ul, .socialbtns li {
  margin: 0;
  padding: 5px;
}

.socialbtns li {
    list-style: none outside none;
    display: inline-block;
    margin-right: 2px;
}
// .socialbtns {
//     margin-right: 320px;
// }

.socialbtns a {
	color: #fff;
	background-color: #FFF;
}

.socialbtns a:hover {
	color: rgb(178, 104, 248);
	background-color: #000;
}
.socialbtns{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
