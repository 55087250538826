#tech {
  position: relative;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  @include desktop-screen {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
  }
  img {
    height: 4rem;
    width: 4rem;
  }
}